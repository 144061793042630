import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import PostItem from "components/resource-list"

const StyledContainer = styled(Container)`
  max-width: 1200px; 
  padding: 0;
  margin: 0 auto;

  h3 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	padding: 0;

	${breakpoint.small`
		font-size: 22px;
		margin-left: 40px;
		text-align: left;
	`}
}
  `

const StyledVideos = styled.section`
	padding: 0;
`

const Videos = props => {
	const data = useStaticQuery(graphql`
    query {
		allWpVideo(
			filter: {videos: {visbyDownloads: {eq: true}, product: {eq: "Sexual Health Test"}}}
			sort: {fields: date, order: DESC}, limit:6) {
			nodes {
				id
				title
				slug
				date
				videos {
				  description
				  url
				  category
				  publishDate
				  fullDescription
				  videoImage {
					  localFile {
					publicURL
					  childImageSharp {
						  gatsbyImageData
					  }
					}
				  }
				}
			  }
			}
		  }
		`)
		return (
			<StyledVideos>
			<StyledContainer>
				<div className="fullList">
					{data.allWpVideo.nodes.map((video) => (
						<PostItem
						key={video.id}
						title={video.title}
						date={video.date}
						link={"/resources/videos/" + video.slug}
						image={video.videos.videoImage.localFile}
						publicURL={video.videos.videoImage.localFile.publicURL}
						linkText="View Video"
						newWindow="_blank"
						relRef="noopener noreferrer"
						/>
					))}
				</div>
			</StyledContainer>
		</StyledVideos>
	)
}

export default Videos